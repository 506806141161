<template>
  <div>
    <div class="card card-nav-tabs card-plain py-5">
      <div class="card-header card-header-success">
        <v-row justify="center"
          ><p class="text-white font-weight-bold pt-2" style="font-size:1rem;">
            {{ cuenta.cuenta }}
          </p></v-row
        >
        <v-row>
          <v-col>
            <p class="text-white font-weight-bold text-t">
              Razón Social:
              {{ CLIENTE_CUENTA_DATA.razon_social }}
            </p>
          </v-col>
          <v-col>
            <p class="text-white font-weight-bold text-t">
              CUIT :
              {{ CLIENTE_CUENTA_DATA.cuit }}
            </p>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-divider vertical></v-divider>

        <div class="nav-tabs-navigation">
          <div class="nav-tabs-wrapper">
            <ul
              class="nav nav-tabs"
              data-tabs="tabs"
              style="justify-content: space-around"
            >
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="`#tab_${cuenta.cuenta.replace(/ /g, '_')}_1`"
                  data-toggle="tab"
                  ><strong>Datos de la Cuenta</strong></a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="`#tab_${cuenta.cuenta.replace(/ /g, '_')}_2`"
                  data-toggle="tab"
                  ><strong>Configuración URL</strong></a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  :href="`#tab_${cuenta.cuenta.replace(/ /g, '_')}_3`"
                  data-toggle="tab"
                  ><strong>Comisiones</strong></a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="tab-content text-center">
          <div
            class="tab-pane"
            :id="`tab_${cuenta.cuenta.replace(/ /g, '_')}_1`"
          >
            <!-- TABLE DE DATOS DE UNA CUENTA -->
            <div class="overflow-x-auto border">
              <table class="table-auto w-full">
                <thead class="">
                  <tr>
                    <th class="px-4 py-2">CBU</th>
                    <!-- <th class="px-4 py-2">CVU</th> -->
                    <th class="px-4 py-2">Rubro</th>
                    <!-- <th class="px-4 py-2">Retiro Automatico</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="px-4 py-2">{{ cuenta.cbu }}</td>
                    <!-- <td class="px-4 py-2">{{ cuenta.cvu ? cuentas.cvu : "sin configuracion" }}</td> -->
                    <td class="px-4 py-2">{{ cuenta.rubro.rubro }}</td>
                    <!-- <td class="px-4 py-2">{{ cuenta.dias_retiro_automatico }} días</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="tab-pane"
            :id="`tab_${cuenta.cuenta.replace(/ /g, '_')}_2`"
          >
            <div class="overflow-x-auto border">
              <table class="table-auto w-full">
                <thead class="">
                  <tr>
                    <th class="px-4 py-2">Nombre</th>
                    <th class="px-4 py-2">URL de Éxito</th>
                    <th class="px-4 py-2">URL Pendiente</th>
                    <th class="px-4 py-2">URL de Notificación</th>
                    <th class="px-4 py-2"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="tipopago in cuenta.tipos_pago"
                    v-bind:key="tipopago.PagoTipo"
                  >
                    <td
                      class=" px-4 py-2 max-w-xs overflow-hidden whitespace-normal break-words"
                    >
                      {{ tipopago.PagoTipo }}
                    </td>
                    <td
                      class="px-4 py-2 max-w-xs overflow-hidden whitespace-normal break-words"
                    >
                      {{
                        tipopago.BackUrlSuccess
                          ? tipopago.BackUrlSuccess
                          : "sin configuracion"
                      }}
                    </td>
                    <td
                      class=" px-4 py-2 max-w-xs overflow-hidden whitespace-normal break-words"
                    >
                      {{
                        tipopago.BackUrlPending
                          ? tipopago.BackUrlPending
                          : "sin configuracion"
                      }}
                    </td>
                    <td
                      class=" px-4 py-2 max-w-xs overflow-hidden whitespace-normal break-words"
                    >
                      {{
                        tipopago.BackUrlNotificacionPagos
                          ? tipopago.BackUrlNotificacionPagos
                          : "sin configuracion"
                      }}
                    </td>
                    <td
                      class=" px-4 py-2 max-w-xs overflow-hidden whitespace-normal break-words"
                    >
                      <v-btn color="info" @click="abrirModalEditar(tipopago)">
                        <i class="material-icons">create</i>
                      </v-btn>
                    </td>

                    <!-- Modal de Editar Tipo Pago -->
                    <v-dialog
                      v-model="dialogEdit"
                      max-width="800px"
                      @click:esc="dialogEdit = false"
                    >
                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          <v-icon class="mr-2" large color="cyan"
                            >mdi-link-edit</v-icon
                          >
                          Editar
                        </v-card-title>
                        <v-card-text>
                          <v-col>
                            <v-row class="mt-3">
                              <v-text-field
                                v-model="BackUrlSuccess"
                                label="URL de Éxito"
                                :rules="reglaURL"
                              ></v-text-field>
                            </v-row>
                            <v-row>
                              <v-text-field
                                v-model="BackUrlPending"
                                label="URL Pendiente"
                                :rules="reglaURL"
                              ></v-text-field>
                            </v-row>
                            <v-row>
                              <v-text-field
                                v-model="BackUrlNotificacionPagos"
                                label="URL de Notificación"
                                :rules="reglaURL"
                              ></v-text-field>
                            </v-row>
                          </v-col>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <span class="grid grid-cols-2 gap-4">
                            <v-btn
                              text
                              color="red darken-1"
                              @click="dialogEdit = false"
                              outlined
                            >
                              Cerrar
                            </v-btn>
                            <v-btn
                              text
                              color="blue darken-1"
                              :loading="load"
                              @click="
                                editarPagosTipo(cuenta.id, tipopago.PagoTipo)
                              "
                              outlined
                            >
                              Actualizar
                            </v-btn>
                          </span>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </tr>
                  <tr v-if="!cuenta.tipos_pago">
                    <td
                      class="px-4 py-2 max-w-xs overflow-hidden whitespace-normal break-words"
                    >
                      Sin registros
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="tab-pane active"
            :id="`tab_${cuenta.cuenta.replace(/ /g, '_')}_3`"
          >
            <div
              class="d-flex justify-end mx-5"
              style="display: none !important;"
            >
              <v-checkbox v-model="showAll">
                <template v-slot:label>
                  <span style="color: #000; font-family: Montserrat">
                    Ver todas las comisiones
                  </span>
                </template></v-checkbox
              >
            </div>

            <div class="font-weight-bold mx-5 py-3">
              {{ cuenta.rubro.rubro }}
            </div>
            <v-simple-table class="elevation-0 pt-4 ">
              <template v-slot:default>
                <thead class="table-auto w-full">
                  <tr>
                    <th
                      class="text-center text-dark font-weight-bold   text-subtitle-2"
                    >
                      Medios de Pago
                    </th>

                    <th
                      class="text-center text-dark font-weight-bold   text-subtitle-2"
                    >
                      Mínimo
                    </th>

                    <th
                      class="text-center text-dark font-weight-bold   text-subtitle-2"
                    >
                      Máximo
                    </th>

                    <th
                      class="text-center text-dark font-weight-bold   text-subtitle-2"
                    >
                      Tarifas
                    </th>
                    <th
                      class="text-center text-dark font-weight-bold   text-subtitle-2"
                    >
                      Vigencia desde
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="comision in showComisiones.filter(
                      (c) => c.canal != 'QR-FACTURA' && c.canal != 'QR-ESTATICO'
                    )"
                    :key="
                      comision.nombre + cuenta.cbu + comision.vigencia_desde
                    "
                    :class="
                      comision.vigente && showAll ? 'green lighten-5 ' : ''
                    "
                  >
                    <td>
                      {{
                        `${
                          comision.canal == "OFFLINE"
                            ? "RAPIPAGO"
                            : comision.canal
                        } 
                        ${comision.medio_pago_id == 30 ? " AMEX" : ""}
                        ${comision.pago_cuota ? " EN CUOTAS" : ""}
                        `
                      }}
                    </td>
                    <td>{{ comision.minimo }}</td>
                    <td>{{ comision.maximo }}</td>
                    <td>
                      {{ (decimales(comision.comision) * 100).toFixed(2) }}%
                    </td>
                    <td>
                      {{
                        moment.utc(comision.vigencia_desde).format("DD/MM/YYYY")
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { PagosTipo } from "../../services/pagostipo";

export default {
  name: "DatosCuentas",

  props: {
    cuenta: {
      type: Object,
      default: () => ({
        cuenta: {
          id: 1,
          cuenta: "Cuenta Prueba",
          cbu: "0940099330007439910060",
          cvu: "",
          dias_retiro_automatico: 30,
          rubro: {
            id: 1,
            rubro: "TASAS, IMPUESTOS Y/O GRAVÁMENES",
          },
          comisiones: [
            {
              nombre: "COMISION 1",
              comision: 0.1,
              vigencia_desde: "2022-08-04",
              canal: "CREDITO",
            },
          ],
          tipos_pago: [
            {
              PagoTipo: "PAGOSTELCO",
              BackUrlSuccess: "https://www.google.com",
              BackUrlPending: "https://www.google.com",
              BackUrlRejected: "",
              BackUrlNotificacionPagos: "",
            },
          ],
        },
      }),
    },
  },
  data: () => ({
    load: false,
    filtroCuentas: [],
    showAll: false,
    moment,
    showComisiones: [],
    dialogEdit: false,
    Id: "", //PagoTipoID
    CuentasId: "",
    PagoTipo: "",
    BackUrlSuccess: "",
    BackUrlPending: "",
    BackUrlRejected: "",
    BackUrlNotificacionPagos: "",

    mostrarRegla: false,
  }),
  watch: {
    showAll(all) {
      if (!all) {
        this.showComisiones = this.cuenta.comisiones.filter((c) => c.vigente);
      } else this.showComisiones = this.cuenta.comisiones;
    },
    "cuenta.comisiones"(newValue) {
      this.showComisiones = newValue.filter((c) => c.vigente);
    },
  },

  methods: {
    decimales(comision) {
      return comision.toFixed(4);
    },

    abrirModalEditar(tipopago) {
      // Establecer los valores de los campos de texto en el modal con los valores de tipopago
      this.Id = tipopago.Id;
      this.CuentasId = tipopago.CuentasId;
      this.BackUrlSuccess = tipopago.BackUrlSuccess
        ? tipopago.BackUrlSuccess
        : "";
      this.BackUrlPending = tipopago.BackUrlPending
        ? tipopago.BackUrlPending
        : "";
      this.BackUrlRejected = tipopago.BackUrlRejected
        ? tipopago.BackUrlRejected
        : "";
      this.BackUrlNotificacionPagos = tipopago.BackUrlNotificacionPagos
        ? tipopago.BackUrlNotificacionPagos
        : "";

      // Abrir el modal
      this.dialogEdit = true;
    },

    async editarPagosTipo(id, pagotipo) {
      if (
        !this.BackUrlSuccess.trim() ||
        !this.BackUrlPending.trim() ||
        !this.BackUrlNotificacionPagos.trim()
      ) {
        // Si la consulta está vacía, muestra un mensaje de error o realiza alguna acción adecuada
        // console.error("El campo no puede estar vacía");
        this.mostrarRegla = true;
        return;
      }
      try {
        const payload = {
          Id: this.Id,
          CuentasId: this.CuentasId,
          Pagotipo: pagotipo,
          BackUrlSuccess: this.BackUrlSuccess,
          BackUrlPending: this.BackUrlPending,
          BackUrlRejected: this.BackUrlRejected,
          BackUrlNotificacionPagos: this.BackUrlNotificacionPagos,
        };
        this.load = true;
        const response = await PagosTipo.actualizar_pagotipogeneral(payload);
        this.load = false;
        this.$store.dispatch("getClientAllData", this.cliente_ID);
        this.dialogEdit = false;

        return response;
      } catch (error) {
        // console.error("Error editando Pago Tipo:", error);
        this.load = false;
        throw error;
      }
    },
  },

  computed: {
    ...mapState(["CLIENTE_CUENTA_DATA", "cliente_ID"]),
    reglaURL() {
      return this.mostrarRegla
        ? [(v) => !!v || "Este campo debe ser una direccion URL válido"]
        : [];
    },
  },
};
</script>
<style scoped>
.break-long-words {
  word-break: break-all;
}
.text-t {
  font-size: 0.9rem;
}
</style>
