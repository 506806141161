<template>
  <div>
    <div class="row">
      <div v-if="procensando_datos">
        <loader
          object="#4caf50"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="40"
          disableScrolling="false"
          name="box"
        ></loader>
      </div>

      <div class="col-md-2" align="left">
        <v-btn fab dark x-small color="primary">
          <router-link to="/administrar-clientes">
            <v-icon dark> arrow_back</v-icon>
          </router-link>
        </v-btn>
      </div>
      <div class="col" align="right">
        <v-row justify="center">
          <v-dialog v-model="createCuenta" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <div class="col-md-12" align="right">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="abrir_create()"
                >
                  Nueva cuenta
                </v-btn>
              </div>
            </template>

            <v-card>
              <v-card-title>
                <span class="text-h5">Crear nueva cuenta</span>
                <v-spacer></v-spacer>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="cerrar_create()"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <!-- Formulario de crear nueva cuenta -->
                  <v-form ref="form_create" v-model="valid" lazy-validation>
                    <v-text-field
                      v-model="cuenta"
                      :counter="25"
                      :rules="namecuenta"
                      label="Nombre de Cuenta"
                      required
                    ></v-text-field>

                    <!-- este campo debe estar verificado (CBU) -->
                    <v-text-field
                      v-model="cbu"
                      :counter="22"
                      :rules="namecbu"
                      label="CBU"
                      :append-icon="show1 ? 'thumb_up_alt' : 'thumb_down_alt'"
                      required
                    >
                      <template v-slot:append-outer>
                        <v-menu style="top: -12px" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              style="margin-left: 20px"
                              v-bind="attrs"
                              v-on="on"
                              @click="verificar_cbu()"
                            >
                              <v-icon center> sync </v-icon>
                            </v-btn>
                          </template>
                        </v-menu>
                      </template>
                    </v-text-field>
                    <!-- CVU -->
                    <v-text-field
                      v-model="cvu"
                      :counter="22"
                      :rules="namecvu"
                      label="CVU"
                      required
                    ></v-text-field>
                    <!-- RUBRO -->
                    <v-select
                      v-model="selectrubro"
                      :items="rubro.data"
                      item-text="rubro"
                      :item-value="'id'"
                      :rules="[(v) => !!v || 'RUBRO es requerido']"
                      label="RUBRO"
                      required
                    ></v-select>
                    <v-row>
                      <v-col>
                        <!-- TRANSFERENCIA AUTOMATICA -->
                        <v-checkbox
                          v-model="transferencia_automatica"
                          label="Transferencia automática"
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <!-- DIAS DE RETIRO AUTOMATICO -->
                        <v-text-field
                          v-model="dias_retiro_automatico"
                          min="0"
                          max="30"
                          type="number"
                          label="Días para retiro automático"
                          :rules="diasRetiroRule"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <!-- split de cuentas -->
                    <v-checkbox
                      v-model="split_cuentas"
                      label="Split de cuentas"
                    >
                    </v-checkbox>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cerrar_create()">
                  Cerrar
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  :loading="dialog"
                  color="blue darken-1"
                  text
                  @click="guardar_cuenta()"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
    </div>
    <br />
    <!-- DATATABLE -->
    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Cuentas del cliente {{ cliente }}</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                border="left"
                class="mt-4 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde.
              </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section v-else>
              <!-- FILTRO BUSCAR -->
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="find_in_page"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <!-- END FILTRO BUSCAR -->
              <v-data-table
                :search="search"
                :headers="headers"
                :items="desserts"
                :loading="cargando"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                @page-count="pageCount = $event"
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                class="elevation-1 trow"
              >
                <template v-slot:no-results>
                  <v-alert color="error" icon="warning">
                    found no results.
                  </v-alert>
                </template>
                <!-- ACCIONES BOTONES -->
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <!-- modal editar cuenta  -->
                    <v-dialog v-model="dialog" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Editar cuenta</span>
                          <v-spacer></v-spacer>
                          <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="close"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </template>
                          </v-menu>
                        </v-card-title>

                        <v-form
                          ref="form_create"
                          v-model="valid"
                          lazy-validation
                        >
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="cuentaedit"
                                    label="Cuenta"
                                    :counter="25"
                                    :rules="namecuenta"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <!-- validar cbu que se va editar -->
                                <!-- <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="cbuedit"
                                    :counter="22"
                                    :rules="namecbu"
                                    label="CBU"
                                    :append-icon="
                                      show1 ? 'thumb_up_alt' : 'thumb_down_alt'
                                    "
                                    required
                                  >
                                    <template v-slot:append-outer>
                                      <v-menu style="top: -12px" offset-y>
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            style="margin-left: 20px"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="verificar_cbu()"
                                          >
                                            <v-icon center>
                                              credit_score
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                      </v-menu>
                                    </template>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="cvuedit"
                                    :counter="30"
                                    :rules="namecvu"
                                    label="CVU"
                                  ></v-text-field>
                                </v-col> -->

                                <!-- api key contiene accion para regenerar uuid -->
                                <!-- <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                v-model="apikeyedit"
                                :rules="nameapikey"
                                label="API KEY"
                                :append-icon="show3 ? 'visibility' : 'visibility_off'"
                                :type="show3 ? 'text' : 'password'"
                                required
                                readonly
                                @click:append="show3 = !show3"
                             >       
                                <template v-slot:append-outer>
                                    <v-menu
                                      style="top: -12px"
                                      offset-y
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="regenerate_apikey"
                                        >
                                          <v-icon center>
                                            sync
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                    </v-menu>
                                </template>
                            </v-text-field>  
                           </v-col> -->

                                <v-col cols="12" sm="12" md="12">
                                  <v-select
                                    v-model="selectrubros_idedit"
                                    :items="rubro.data"
                                    item-text="rubro"
                                    :item-value="'id'"
                                    :rules="[
                                      (v) => !!v || 'RUBRO es requerido',
                                    ]"
                                    label="RUBRO"
                                    required
                                  ></v-select>
                                </v-col>
                                <v-row style="margin-left: 1px;">
                                  <!-- editar transferencia automatica -->
                                  <v-col cols="6">
                                    <v-checkbox
                                      class="ckeck"
                                      v-model="edit_transferencia_automatica"
                                      label="Transferencia automática"
                                    ></v-checkbox>
                                  </v-col>
                                  <!-- Editar dias para retiro automatico -->
                                  <v-col cols="6" class="px-6">
                                    <v-text-field
                                      v-model="edit_dias_retiro_automatico"
                                      min="0"
                                      max="30"
                                      type="number"
                                      label="Días para retiro automático"
                                      :rules="diasRetiroRule"
                                    ></v-text-field>
                                  </v-col>

                                  <!-- editar split de cuentas -->
                                  <v-col cols="6">
                                    <v-checkbox
                                      class="ckeck"
                                      v-model="edit_split_cuentas"
                                      label="Split de cuentas"
                                    ></v-checkbox>
                                  </v-col>

                                  <v-col cols="6">
                                    <v-checkbox
                                      class="ckeck"
                                      v-model="cobro_app"
                                      label="Habilitar cobro por App"
                                    ></v-checkbox>
                                  </v-col>
                                  <!-- <v-col
                                    cols="6"
                                    margin="0"
                                    style="padding-top: 0;"
                                  >
                                    <v-checkbox
                                      style="padding-top: 0;"
                                      v-model="cobro_pos"
                                      label="Cobrar por pos"
                                    ></v-checkbox>
                                  </v-col> -->
                                </v-row>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancelar
                            </v-btn>
                            <v-btn
                              :disabled="!valid"
                              color="blue darken-1"
                              text
                              @click="actualizar"
                            >
                              Actualizar
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>

                    <!-- modal para aditar apikey -->
                    <v-dialog v-model="dialogEditApikey" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Editar apikey</span>
                          <v-spacer></v-spacer>
                          <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="closeApikey"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </template>
                          </v-menu>
                        </v-card-title>

                        <v-form
                          ref="form_create"
                          v-model="valid"
                          lazy-validation
                        >
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <!-- api key contiene accion para regenerar uuid -->
                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="apikeyedit"
                                    :rules="nameapikey"
                                    label="API KEY"
                                    :append-icon="
                                      show3 ? 'visibility' : 'visibility_off'
                                    "
                                    :type="show3 ? 'text' : 'password'"
                                    required
                                    readonly
                                    @click:append="show3 = !show3"
                                  >
                                    <template v-slot:append-outer>
                                      <v-menu style="top: -12px" offset-y>
                                        <!-- <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="regenerate_apikey"
                                        >
                                          <v-icon center>
                                            sync
                                          </v-icon>
                                        </v-btn>
                                      </template> -->
                                      </v-menu>
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeApikey()"
                            >
                              Cancelar
                            </v-btn>
                            <v-btn
                              :disabled="!valid"
                              color="blue darken-1"
                              text
                              @click="regenerate_apikey"
                            >
                              Actualizar apikey
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>

                    <!-- modal eliminar -->
                    <v-dialog v-model="dialogDelete" max-width="400px">
                      <v-card>
                        <v-card-title
                          class="text-h5 justify-center"
                          :v-text="cuenta_cuenta"
                        ></v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >Confirmar</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <DialogContactos ref="dialogContactos" />
                  </v-toolbar>
                </template>
                <!-- BOTONES DE LA TABLA -->
                <template v-slot:[`item.actions`]="{ item }">
                  <!-- <v-icon
                    color="orange"
                    @click="editItem(item)"
                    >
                    create  
                  </v-icon> -->
                  <!-- 1 boton editar cuenta -->
                  <v-tooltip top color="orange">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        color="orange"
                        @click="editItem(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> create </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar cuenta</span>
                  </v-tooltip>

                  <!-- 2. editar apikey -->
                  <v-tooltip
                    top
                    color="info"
                    v-if="permisos.some((p) => p === 'psp.cuentas.apikey')"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        color="info"
                        @click="editItemApiKey(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> key </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar apikey</span>
                  </v-tooltip>

                  <!-- Contactos -->

                  <v-tooltip top color="yellow darken-2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        x-small
                        color="yellow darken-2"
                        :dark="reporte_cuenta"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!reporte_cuenta"
                        @click="showDialogContactos(item)"
                      >
                        <v-icon dark> mdi-notebook-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Contactos</span>
                  </v-tooltip>

                  <!-- 3. Pagos Tipo  -->
                  <v-tooltip
                    top
                    color="cyan darken-4"
                    v-if="permisos.some((p) => p === 'psp.admin.pagotipo')"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        color="cyan darken-4"
                        @click="cargar_tipo_pago(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> credit_score </v-icon>
                      </v-btn>
                    </template>
                    <span>Tipo de Pago</span>
                  </v-tooltip>

                  <!-- 4. Cuenta Comision -->
                  <v-tooltip
                    top
                    color="grey darken-1"
                    v-if="
                      permisos.some(
                        (p) =>
                          p === 'psp.admin.cuentacomisiones' ||
                          p === 'psp.consultar.cuenta_comision'
                      )
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        color="grey darken-1"
                        @click="cargar_cuenta_comision(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> attach_money </v-icon>
                      </v-btn>
                    </template>
                    <span>Comisión</span>
                  </v-tooltip>
                  <!-- 5. Sub Cuentas -->
                  <v-tooltip top color="cyan darken-4">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        :dark="item.split_cuentas"
                        x-small
                        color="lime darken-4"
                        @click="cargar_sub_cuentas(item)"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!item.split_cuentas"
                      >
                        <v-icon dark> group </v-icon>
                      </v-btn>
                    </template>
                    <span>Split Cuentas</span>
                  </v-tooltip>
                  <!-- 6. Puntos de venta -->
                  <v-tooltip
                    top
                    color="blue"
                    v-if="item.cobro_pos || item.cobro_app"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        color="blue"
                        @click="puntos_venta(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-text-account </v-icon>
                      </v-btn>
                    </template>
                    <span>Puntos de venta</span>
                  </v-tooltip>
                </template>
                <!--END  BOTONES DE LA TABLA -->
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination v-model="page" :length="pageCount"></v-pagination>
              </div>
            </section>
          </div>

          <!-- verificacion de cbu  -->
          <v-row justify="center">
            <v-dialog v-model="verificacioncbu" width="600px">
              <v-card>
                <v-card-title>
                  <span class="text-h5"
                    ><center>Datos CBU {{ datoscbu }}</center></span
                  >
                </v-card-title>
                <v-card-text>
                  <p class="font-weight-black">Entidad Bancaria</p>
                  Nombre: {{ entidad_bancaria.Nombre }} - Nombre Abreviado:
                  {{ entidad_bancaria.NombreAbreviado }} <br /><br />
                  <!-- Lista de titulares de cuenta  -->
                  <p class="font-weight-black">Titular</p>
                  <v-list-item two-line>
                    <v-list-item-content
                      v-for="titular in titulares"
                      :key="titular.id"
                    >
                      <v-list-item-subtitle
                        >Tributario:
                        {{ titular.IdTributario }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >Denominación:
                        {{ titular.Denominacion }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="verificacioncbu = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn color="green darken-1" text @click="confirmar_cbu">
                    Confirmar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-container>
      </v-main>
      <DialogContactos ref="dialogContactos" />
    </v-app>
  </div>
</template>
<script>
import { Cuentas } from "../../services/cuentas.js";
import { Rubros } from "../../services/rubros.js";
import { mapGetters } from "vuex";
import DialogContactos from "./Cuentas/DialogContactos.vue";

export default {
  components: { DialogContactos },
  data: () => ({
    dias_retiro_automatico: 1,
    transferencia_automatica: false,
    name: "Cuentas",
    // efecto cargando en tabla
    cargando: false,
    dialog: false,
    dialogDelete: false,
    dialogEditApikey: false,
    // mostrar error de api
    errored: false,
    // res indica el resultado de las validaciones
    res: false,
    //loaders para procesar datos dee envio
    procensando_datos: false,
    // Filter models.
    search: "",
    headers: [
      { text: "Cuenta", value: "cuenta" },
      { text: "CBU", value: "cbu" },
      { text: "Rubro", value: "rubro.rubro" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    // paginate datatable
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    //Datos del cliente
    cliente_id: 0, // id del cliente
    cliente: "", // nombre del cliente
    split_cuentas: false,
    split_de_cliente: 0,
    reporte_cuenta: "",

    // cuentas del cliente
    desserts: [],
    cobro_app: false,
    cobro_pos: false,
    cuenta_cuenta: "",
    cuenta_id: "",

    // nueva cuenta POST
    createCuenta: false,
    cbu: "",
    cvu: "",
    cuenta: "",
    selectrubro: null,
    rubro: [],

    //  campos a actualizar nueva cuenta PUT
    cuentaedit: "",
    cbuedit: "",
    cvuedit: "",
    apikeyedit: "",
    selectrubros_idedit: null,
    edit_dias_retiro_automatico: 0,
    edit_transferencia_automatica: false,
    edit_split_cuentas: false,
    // campo oculto api key
    show3: false,
    // validaciones cuenta
    valid: false,
    namecuenta: [
      (v) => !!v || "Cuenta es requerido",
      (v) =>
        (v && v.length <= 25) || "Cuenta no debe superar los 25 caracteres",
      (v) =>
        /^[A-Za-z0-9.-\s]+$/.test(v) ||
        "Solo numero y letras, sin ñ o acentos.",
    ],
    namecbu: [
      (v) => !!v || "CBU es requerido",
      (v) =>
        (v && v.length <= 22) ||
        "El campo CBU no debe superar los 22 caracteres",
      (v) => /^[0-9]+$/.test(v) || "El campo CBU es númerico",
    ],
    diasRetiroRule: [
      (v) => parseInt(v) >= 0 || "Los dias son requeridos",
      (v) => parseInt(v) < 31 || "El maximo de dias es 30",
    ],
    namecvu: [
      // v => (v && v.length <= 22) || 'El campo CVU no debe superar los 22 caracteres',
      // v => /^[0-9]+$/.test(v) || 'El campo CVU es númerico',
    ],
    nameapikey: [(v) => !!v || "API KEY es requerido"],

    // verificar cbu
    show1: false,
    verificacioncbu: false,
    titulares: [],
    entidad_bancaria: "",
    // si la cuenta esta verificada muestra el icono
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogEditApikey(val) {
      val || this.closeDelete();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.cliente_id = this.$route.params.cliente_id;
    this.getdatos();
    this.getrubros();
  },

  computed: {
    datoscbu() {
      if (this.cbu != "") {
        return this.cbu;
      } else {
        return this.cbuedit;
      }
    },

    ...mapGetters(["permisos"]),
  },
  //consultar las cuentas del cliente
  methods: {
    //MODAL CONTACTOS DE LA CUENTA
    showDialogContactos(item) {
      this.$refs.dialogContactos.$emit("showDialogContactos", item);
    },

    cargar_cuenta_comision(item) {
      this.$router.push({
        name: "CuentaComision",
        params: { cliente_id: this.cliente_id, cuenta_id: item.id },
      });
    },
    cargar_tipo_pago(item) {
      this.$router.push({
        name: "TiposPagos",
        params: { cliente_id: this.cliente_id, cuenta_id: item.id },
      });
    },
    cargar_sub_cuentas(item) {
      this.$router.push({
        name: "SubCuentas",
        params: { cliente_id: this.cliente_id, cuenta_id: item.id },
      });
    },
    puntos_venta(item) {
      this.$router.push({
        name: "IndexPuntosVentas",
        params: { cliente_id: this.cliente_id, cuenta_id: item.id },
      });
    },
    getdatos: function() {
      var data = new FormData();
      data.append("id", this.cliente_id);
      data.append("CargarCuentas", true);
      data.append("CargarRubros", true);
      data.append("CargarImpuestos", true);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.cargando = true;
      Cuentas.getCuentas(data, config)

        .then((response) => {
          if (response?.data?.cuenta == null) {
            this.desserts = [];
          } else {
            this.split_de_cliente = response?.data?.clientes_splits_id;
            this.reporte_cuenta = response?.data?.reportes_por_cuenta;
            this.desserts = response?.data?.cuenta;
          }
          this.split_de_cliente = response?.data?.clientes_splits_id;

          this.cliente = response?.data?.cliente;
          this.cliente_id = response?.data?.id;
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },

    // verificar cbu
    verificar_cbu() {
      var payload = {};
      if (this.cbu != "") {
        payload = {
          Cbu: this.cbu,
        };
        this.valid_cbu(payload);
      } else {
        payload = {
          Cbu: this.cbuedit,
        };
        this.valid_cbu(payload);
      }
    },

    valid_cbu(payload) {
      if (payload.Cbu == "") {
        this.$toastr.i("Debe ingresar un CBU");
      } else {
        this.procensando_datos = true;
        Cuentas.verificar_cbu(payload)
          .then((response) => {
            this.entidad_bancaria = response.data.EntidadBancaria;
            this.titulares = response.data.Titulares;
            this.verificacioncbu = true;
          })
          .catch((error) => {
            var data = error.response.data;
            this.error = true;
            this.msg = data.message;
            this.show1 = false;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.procensando_datos = false));
      }
    },

    regenerate_apikey() {
      const payload = {
        id: this.cuenta_id,
        apikey: this.apikeyedit,
        clientes_id: this.cliente_id,
      };
      this.error = false;
      this.procensando_datos = true;
      Cuentas.actualizar_apikey(payload)
        .then((response) => {
          this.apikeyedit = response.data.api_key;
          this.$toastr.s("API KEY se actualizo con éxito");
        })
        .catch((error) => {
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.procensando_datos = false));
    },

    confirmar_cbu() {
      this.verificacioncbu = false;
      this.show1 = true;
    },

    abrir_create() {
      this.createCuenta = true;
      this.show1 = false;
    },

    cerrar_create() {
      this.createCuenta = false;
      this.$refs.form_create.reset();
      this.limpiar_campos();
    },

    //  guardar nueva cuenta
    async guardar_cuenta() {
      var payload = {
        clientes_id: parseInt(this.cliente_id),
        rubros_id: this.selectrubro,
        cuenta: this.cuenta.toUpperCase(),
        cbu: this.cbu,
        cvu: this.cvu,
        apikey: this.apikey,
        dias_retiro_automatico: parseInt(this.dias_retiro_automatico),
        transferencia_automatica: this.transferencia_automatica,
        split_cuentas: this.split_cuentas,
      };

      if (this.show1 == false) {
        this.$toastr.i("Debe validar el CBU ingresado");
      } else {
        if (
          this.split_de_cliente !== 0 ||
          (this.split_de_cliente === 0 && this.split_cuentas === false)
        ) {
          this.validate();
          if (this.res === true) {
            this.error = false;
            this.procensando_datos = true;

            await Cuentas.nueva_cuenta(payload)
              .then(() => {
                this.$toastr.s("La cuenta se agrego correctamente");
                this.createCuenta = false;
                this.limpiar_campos();
                this.getdatos();
              })
              .catch((error) => {
                var data = error.response.data;
                this.error = true;
                this.$toastr.e(data.message);
              })
              .finally(() => (this.procensando_datos = false));
          } else {
            this.$toastr.e(
              "Error al enviar formulario, verificar datos de envio"
            );
          }
        } else {
          this.$toastr.i(
            "No puede existir Split de Cuenta en un Cliente sin Split"
          );
        }
      }
    },
    // Consultar rubros
    getrubros: function() {
      const params = {
        Number: 1,
        Size: 20,
      };
      this.cargando = true;
      Rubros.getRubros(params)
        .then((response) => {
          this.rubro = response.data;
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        });
    },
    //consultar los datos de la cuenta a modificar
    editItem(item) {
      this.consultar_cuenta(item);
      this.dialog = true;
    },

    editItemApiKey(item) {
      this.consultar_cuenta(item);
      this.dialogEditApikey = true;
    },

    //  consultar el id de la cuenta a eliminar
    deleteItem(item) {
      this.consultar_cuenta(item);
      this.dialogDelete = true;
    },

    // confirmar la cuenta a eliminar
    deleteItemConfirm() {
      const params = {
        id: this.cuenta_id,
      };
      this.error = false;
      this.loading = true;
      this.procensando_datos = true;
      Cuentas.delete_cuenta(params)
        .then((response) => {
          // mensaje de exito
          this.$toastr.s("La cuenta se elimino con éxito");
          // actualziar tabla de clientes
          this.getdatos();
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.loading = false;
          this.$toastr.e(data.message);
          this.dialog = false;
          this.procensando_datos = false;
        })
        .finally(() => (this.cargando = false));
      this.closeDelete();
    },

    // actualizar cuenta
    async actualizar() {
      var payload = {
        id: this.cuenta_id,
        clientes_id: this.cliente_id,
        rubros_id: this.selectrubros_idedit,
        cuenta: this.cuentaedit,
        cbu: this.cbuedit,
        cvu: this.cvuedit,
        dias_retiro_automatico: parseInt(this.edit_dias_retiro_automatico),
        transferencia_automatica: this.edit_transferencia_automatica,

        split_cuentas: this.edit_split_cuentas,

        cobro_app: this.cobro_app,
        cobro_pos: this.cobro_pos,

        // apikey: this.apikeyedit,
      };
      if (this.show1 == false) {
        this.$toastr.e("Debe validar el CBU registrado");
      } else {
        if (
          this.split_de_cliente !== 0 ||
          (this.split_de_cliente === 0 && this.edit_split_cuentas === false)
        ) {
          this.validate();
          if (this.res === true) {
            this.error = false;
            this.dialog = true;
            this.procensando_datos = true;
            await Cuentas.actualizar_cuenta(payload)
              .then((response) => {
                this.dialog = false;
                this.$toastr.s("La cuenta se actualizo correctamente");
                // actualziar tabla de clientes
                this.getdatos();
                this.procensando_datos = false;
                this.close();
              })
              .catch((error) => {
                var data = error.response.data;

                this.error = true;
                this.$toastr.e(data.message);
              })
              .finally(() => (this.procensando_datos = false));
          } else {
            this.$toastr.e(
              "Error al enviar formulario, verificar datos de envio"
            );
          }
        } else {
          this.$toastr.i(
            "No puede existir Split de Cuenta en un Cliente sin Split"
          );
        }
      }
    },

    close() {
      this.cobro_app = false;
      this.cobro_pos = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogEditApikey = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeApikey() {
      this.dialogEditApikey = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    },

    validate() {
      this.$refs.form_create.validate();
      this.res = this.$refs.form_create.validate();
    },

    //consultar la cuenta a eliminar o editar por ID
    consultar_cuenta: function(item) {
      this.procensando_datos = true;
      const params = {
        id: item.id,
      };
      Cuentas.getCuentaID(params)
        .then((response) => {
          this.cuenta_cuenta =
            "¿ Desea eliminar cuenta " + response.data.cuenta + "?";
          this.cuenta_id = response.data.id;
          //cargar los datos en el modal
          this.selectrubros_idedit = response.data.rubros_id;
          this.cuentaedit = response.data.cuenta;
          this.cbuedit = response.data.cbu;
          this.cvuedit = response.data.cvu;
          this.apikeyedit = response.data.apikey;
          this.edit_dias_retiro_automatico =
            response.data.dias_retiro_automatico;
          this.edit_transferencia_automatica =
            response.data.transferencia_automatica;

          this.cobro_app = response.data.cobro_app;
          this.cobro_pos = response.data.cobro_pos;
          this.edit_split_cuentas = response.data.split_cuentas;
          this.cobro_app = response.data.cobro_app;
          this.cobro_pos = response.data.cobro_pos;
          this.show1 = true;
        })
        .catch((error) => {
          var data = error.response.data;
          //mostrar mensaje de error en caso que la variable sea true(tenga datos)
          this.error = true;
          this.msg = data.message;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.procensando_datos = false));
    },

    limpiar_campos() {
      this.cbu = "";
      this.cvu = "";
      this.cuenta = "";
      this.selectrubro = null;
      this.split_cuentas = false;
    },
  },
};
</script>
<style scoped>
a[data-v-1b84c964] {
  color: #e6f1e3;
}

.theme--light.v-btn {
  color: rgb(93 175 118 / 87%);
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}
.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px;
}
</style>
