import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const POSTNET = process.env.VUE_APP_URLPOSTNET;
export const Cuentas = {
  //listar cuentas. se usa el endpoint  /administracion/cliente porque trae las cuentas de un cliente especifico
  getCuentas(data, config) {
    return axios.post(
      PASARELA + "/administracion/obtener-cliente",
      data,
      config
    );
  },

  //consultar cuenta por id
  getCuentaID(params) {
    return axios.get(PASARELA + "/administracion/cuenta", { params });
  },

  // crear nueva cuenta
  nueva_cuenta(payload) {
    return axios.post(PASARELA + "/administracion/cuentas", payload);
  },

  // actualizar cuenta
  actualizar_cuenta(payload) {
    return axios.put(PASARELA + "/administracion/cuenta", payload);
  },

  // actualizar apikey
  actualizar_apikey(payload) {
    return axios.put(PASARELA + "/administracion/cuenta-setkey", payload);
  },

  // delete cuenta
  //   delete_cuenta(params) {
  //     return  axios.delete(PASARELA+"/administracion/cuenta", {params})
  // },

  // verificar cbu
  verificar_cbu(payload) {
    return axios.post(
      PASARELA + "/administracion/consulta-destinatario",
      payload
    );
  },
// getPuntos de venta
  getPuntosdeVenta(number, size, cliente_id){    
    return axios.get(
      PASARELA + '/checkout/puntos-venta/?number=' + number +'&size='+ size +'&CuentaId=' + cliente_id);
  },

  //Crear Punto de venta
  storePuntosdeVenta(body){
    return axios.post(PASARELA + '/checkout/puntos-venta', body);
  },

  //Actualizar punto de venta
  updatePuntodeVenta(body){
    return axios.put(PASARELA + '/checkout/puntos-venta', body);
  },

  //Eliminar punto de venta
  deletePuntodeVenta(id_punto){
    return axios.delete(PASARELA + '/checkout/puntos-venta/' + id_punto);
  },

  //Get datos caja
  getDatosCaja(params){
    return axios.get(PASARELA + '/checkout/cajas-cobro', {params});
  },

  //Crear caja
  storeCaja(body){
    return axios.post(PASARELA + '/checkout/cajas-cobro', body);
  },

  //Actualizar caja
  updateCaja(body){
    return axios.post(PASARELA + '/checkout/cajas-cobro-update', body);
  },

  //Eliminar caja
  deleteCaja(id_caja){
    return axios.delete(PASARELA + '/checkout/cajas-cobro?Id=' + id_caja);
  },
  
  //Cierre sesión
  cerrarSesion(id_user){
    return axios.post(POSTNET + '/postnet/cerrar-sesion/' + id_user);
  }
};
